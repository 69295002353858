import React from "react";
import UserAgentLogger from "./Components/UserAgentLogger";

function App() {
  return (
    <div >

      <UserAgentLogger/>

      
    </div>
  );
}

export default App;
